module.exports = [{
      plugin: require('/var_gatsby/locit-gatsby/locit/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var_gatsby/locit-gatsby/locit/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/var_gatsby/locit-gatsby/locit/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KWV5V7R","includeInDevelopment":false,"dataLayerName":"dataLayer"},
    },{
      plugin: require('/var_gatsby/locit-gatsby/locit/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
