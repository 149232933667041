// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/var_gatsby/locit-gatsby/locit/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-adresy-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/adresy.jsx" /* webpackChunkName: "component---src-pages-adresy-jsx" */),
  "component---src-pages-cennik-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/cennik.jsx" /* webpackChunkName: "component---src-pages-cennik-jsx" */),
  "component---src-pages-dane-statystyczne-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/dane-statystyczne.jsx" /* webpackChunkName: "component---src-pages-dane-statystyczne-jsx" */),
  "component---src-pages-dlaczego-my-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/dlaczego-my.jsx" /* webpackChunkName: "component---src-pages-dlaczego-my-jsx" */),
  "component---src-pages-geokodowanie-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/geokodowanie.jsx" /* webpackChunkName: "component---src-pages-geokodowanie-jsx" */),
  "component---src-pages-index-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-mapy-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/mapy.jsx" /* webpackChunkName: "component---src-pages-mapy-jsx" */),
  "component---src-pages-obliczenia-geograficzne-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/obliczenia-geograficzne.jsx" /* webpackChunkName: "component---src-pages-obliczenia-geograficzne-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-saas-on-premise-jsx": () => import("/var_gatsby/locit-gatsby/locit/src/pages/saas-on-premise.jsx" /* webpackChunkName: "component---src-pages-saas-on-premise-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var_gatsby/locit-gatsby/locit/.cache/data.json")

